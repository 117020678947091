<template>
  <div>
    <!-- :left-arrow="true" -->
    <titleNav :title="title" />
    <!-- 支付失败 -->
    <div v-if="id == 1" class="divse">
      <div class="submit_warp">
        <img src="@/assets/images/pay_fail.png" alt="" />
        <div>支付失败</div>
        <div class="submine">
          <van-button
            size="large"
            @click="submitHnadns"
            style="border: 1px solid rgb(24 180 237); color: rgb(24, 180, 237)"
            >重新支付</van-button
          >
        </div>
        <div class="submines">
          <van-button
            size="large"
            @click="submitHnadn"
            style="border: 1px solid rgb(24 180 237); color: rgb(24, 180, 237)"
            >退出</van-button
          >
        </div>
      </div>
    </div>
    <!-- 支付成功 -->
    <div v-else class="divse">
      <div class="submit_warp">
        <img src="@/assets/images/dui.png" alt="" />
        <div>支付成功</div>
        <div class="submine">
          <van-button
            size="large"
            @click="submitHnadn"
            style="border: 1px solid rgb(24 180 237); color: rgb(24, 180, 237)"
            >完成</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Button, Toast } from "vant";
import { indexs, getopenid } from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Toast.name]: Toast,
  },
  data() {
    return {
      id: 1,
      title: "支付成功",
    };
  },
  created() {
    let id = this.$route.query.id;
    this.id = id;
    if (id == 1) {
      this.title = "支付失败";
    } else {
      this.title = "支付成功";
    }
  },
  mounted() {},
  methods: {
    // 获取code
    codeSplicet() {
      let codes = sessionStorage.getItem("id");
      if (codes == 222 || codes == "222") {
        this.login();
      }
      // subscribe 1是关注 0 未关注
      // openid nickname
      // let codes = sessionStorage.getItem("id");
      // let codes = sessionStorage.getItem("id");
      // if (codes == undefined || codes == null || codes == "undefined") {
      //   let url = window.location.href;
      //   console.log(url,123)
      //   // const codeGet = login.getCode();
      //   sessionStorage.setItem("id", 222);
      // } else {
      //   console.log(3);
      //   this.login();
      // }
    },
    // login
    login() {
      // Toast('123456')
      console.log(5);
      var _this = this;
      let url = window.location.href;
      sessionStorage.setItem("urls", url);
      console.log(url);
      let codes = url.substring(30, 62);
      let codee = sessionStorage.getItem("userAll");
      console.log(codes);

      let data = {
        // code: '031LTYZv3m78ZY2Uv40w3ypE9A2LTYZx',
        code: codes,
        type: 7,
      };
      indexs(data).then((res) => {
        // let id = '3646690';
        let id = res.data.data.id;
        if (id !== undefined) {
          _this.id = id;
          console.log(id, 1);
          sessionStorage.setItem("id", id);
        } else {
          let ids = sessionStorage.getItem("id");
          sessionStorage.setItem("id", ids);
        }
      });
    },
    submitHnadn() {
      if (this.$route.query.code == undefined) {
        this.$router.replace({ path: "/index" });
      } else {
        // location.reload();
        var _this = this;
        let url = window.location.href;
        // sessionStorage.setItem("urls", url);
        // console.log(url);
        let codes = this.$route.query.code;
        // let codee = sessionStorage.getItem("userAll");
        // console.log(codes);

        let data = {
          // code: '051JWvFa1XHrED0fG3Ia1SfJcd4JWvFZ',
          code: codes,
          type: 7,
        };
        // Toast(codes)
        indexs(data).then((res) => {
          // let id = '3646690';
          console.log(res);
          let id = res.data.data.id;
          if (id !== undefined) {
            _this.id = id;
            console.log(id, 1);
            sessionStorage.setItem("id", id);
            // this.$router.push("/index");
            _this.$router.replace({ path: "/index" });
            // location.reload();
          } else {
            let ids = sessionStorage.getItem("id");
            sessionStorage.setItem("id", ids);
          }
        });
      }

      //  _this.$router.go(-7);
    },
    submitHnadns() {
      var _this = this;
      let url = window.location.href;
      // sessionStorage.setItem("urls", url);
      // console.log(url);
      let codes = this.$route.query.code;
      // let codee = sessionStorage.getItem("userAll");
      // console.log(codes);
      let data = {
        // code: '051JWvFa1XHrED0fG3Ia1SfJcd4JWvFZ',
        code: codes,
        type: 7,
      };
      // Toast(codes)
      indexs(data).then((res) => {
        // let id = '3646690';
        console.log(res);
        let id = res.data.data.id;
        if (id !== undefined) {
          _this.id = id;
          console.log(id, 1);
          sessionStorage.setItem("id", id);
          // this.$router.push("/index");
          this.$router.go(-1); //返回上一层
          // location.reload();
        } else {
          let ids = sessionStorage.getItem("id");
          sessionStorage.setItem("id", ids);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.divse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit_warp {
  text-align: center;
  font-size: 16px;
  width: 80%;
  img {
    margin: 20px 0;
    width: 80px;
    text-align: center;
  }
  .submine {
    padding: 10px 0;
    padding-top: 50px;

    .van-button {
      height: 40px;
    }
  }
  .submines {
    padding: 10px 0;
    padding-top: 20px;

    .van-button {
      height: 40px;
    }
  }
}
</style>
